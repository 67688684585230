import { Typography, Link, Box, Grid } from "@mui/material";
import Card from "../../../components/Card/Cards";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./manage-section.module.css";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import CourseTabs from "../course-tabs/course-tabs";
import ImageView from "../../../components/imageShow";
import SwitchCell from "../../../components/switch";
import CSVDownloadButton from "../../../components/downloadCSV";
import { HasPermission } from "../../../helper/helperFunction";

// SECTION MODULE
export default function SectionView() {
  const navigate = useNavigate();
  const params = useParams();
  const [sectionInfo, setSectionInfo] = useState();
  const [courseInfo, setCourseInfo] = useState();
  const [courseID, setCourseID] = useState(null);
  const [tabsCount, setTabsCount] = useState("");



  const [reloadEnroll, setReloadEnroll] = useState(false);
  const [apiUrl, setApiUrl] = useState();
  const course_id = sectionInfo?.course_id ?? "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (course_id) {
          const res = await api.get(
            resolveUrl(AdminApi.COURSES_DETAIL_HEADER_VIEW, course_id)
          );
          setCourseInfo(res.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [course_id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.id) {
          const res = await api.get(
            resolveUrl(AdminApi.SECTION_MODULE_DETAIL, params.id)
          );
          setSectionInfo(res.data.data);
          setCourseID(res.data.data.course_id);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [params.id, tabsCount]);

  const View = async () => {
    navigate(`/admin/section/edit/${params.id}`);
  };

  const [csvName, setCsvName] = useState();
  const [reRender, setReRender] = useState(0);


// Function to transform data
function transformData(data) {
  const result = [];

  // Group by date
  const groupedByDate = data.reduce((acc, entry) => {
    if (!acc[entry.date]) acc[entry.date] = {};
    acc[entry.date][entry.name] = entry.status;
    return acc;
  }, {});

  // Format the final result
  for (const date in groupedByDate) {
    result.push({
      Date: date,
      ...groupedByDate[date]
    });
  }

  return result;
}

  
  useEffect(() => {
    let csvUrl;

    if (tabsCount === "course_outline") {
      if (courseID) {
        csvUrl = resolveUrl(
          AdminApi.COURSE_SECTION_OUTLINE_CSV,
          courseID ?? ""
        );
        setApiUrl(`${csvUrl}?section_id=${params.id}`);
        setCsvName("Course Outline");
      }
    } else if (tabsCount === "enrolled_students") {
      csvUrl = resolveUrl(
        AdminApi.COURSE_STUDENTS_ENROLLED_CSV,
        params.id ?? ""
      );
      setCsvName("Enrolled Students");
      setApiUrl(csvUrl);
    } else if (tabsCount === "assignment") {
      csvUrl = resolveUrl(AdminApi.COURSE_ASSIGNMENTS_CSV, params.id ?? "");
      setCsvName("Assignment");
      setApiUrl(csvUrl);
    } else if (tabsCount === "attendance") {
      csvUrl = resolveUrl(AdminApi.COURSE_ATTENDANCE_CSV, params.id ?? "");
      setCsvName("Attendance");
      setApiUrl(csvUrl);
    } else if (tabsCount === "grade_book") {
      csvUrl = resolveUrl(AdminApi.COURSE_GRADE_CSV, params.id ?? "");
      setCsvName("Grade Book");
      setApiUrl(csvUrl);
    }
  }, [tabsCount, courseID]);

  // const transformStudentData = (data) => {    
  //   return data.map(student => {      
  //     // Flatten assessment data into a single string for CSV
  //     const assessments = student.assessments.map(assessment => 
  //       `${assessment.assessment_type} (Percentage: ${assessment.assessment_percentage.toFixed(2)}%)`
  //     ).join('; ') || "No Assessments"; // Default value if there are no assessments
  
  //     return {
  //       StudentID: student.student_id, 
  //       StudentName: student.student_name, 
  //       Attendance: student.attendance_score ?? 0, 
  //       Workshop: student.workshop_score ?? "0.00", 
  //       Result: student.result ?? "N/A", 
  //       Assessments: assessments 
  //     };
  //   });
  // };
  
  
  const transformStudentData = (data) => {
    return data.map(student => ({
      student_name: student.student_name,
      attendance_score: `${student.attendance_score}%`,
      workshop_score: `${student.workshop_score}%`,
      weekly_assignment_view: `${student.assessments.find(a => a.assessment_type === 'Weekly Assignment').assessment_percentage}%`,
      assignment_1_view: student.assessments.find(a => a.assessment_type === 'Assignment-1').marks ? `${student.assessments.find(a => a.assessment_type === 'Assignment-1').assessment_percentage}%` : 'N/A',
      assignment_2_view: student.assessments.find(a => a.assessment_type === 'Assignment-2').marks ? `${student.assessments.find(a => a.assessment_type === 'Assignment-2').assessment_percentage}%` : 'N/A',
      final_viva_view: student.assessments.find(a => a.assessment_type === 'Final Viva').marks ? `${student.assessments.find(a => a.assessment_type === 'Final Viva').assessment_percentage}%` : 'N/A',
      final_project_view: student.assessments.find(a => a.assessment_type === 'Final Project').marks ? `${student.assessments.find(a => a.assessment_type === 'Final Project').assessment_percentage}%` : 'N/A',
      result: `${student.result}%`,
    }));
  };

  const [reportData, setReportData] = useState([]);
  const [gradeBookData, setGradeBookData] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await api.get(apiUrl, {
        params: {
          sort_order: "desc",
          download_csv:true
        },
      });
      const data = transformData(response.data.data ?? [])
      setReportData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [apiUrl]);

  useEffect(() => {
    if (tabsCount == "attendance") {
      fetchData();
    }
  
  }, [fetchData,csvName,reRender]);

  const fetchDataGrade = useCallback(async () => {
    try {
      const response = await api.get(apiUrl, {
        params: {
          sort_order: "desc",
          download_csv:true
        },
      });
      const grade_data = transformStudentData(response.data.data ?? [])
      
      setGradeBookData(grade_data)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [apiUrl]);

  useEffect(() => {
    if (tabsCount == "grade_book") {
      fetchDataGrade();
    }
  }, [fetchDataGrade,csvName,reRender]);







  const headers = [
    { label: 'Name', key: 'student_name' },
    { label: 'Attendance', key: 'attendance_score' },
    { label: 'Workshop', key: 'workshop_score' },
    { label: 'Weekly Assignment view', key: 'weekly_assignment_view' },
    { label: 'Assignment-1 view', key: 'assignment_1_view' },
    { label: 'Assignment-2 view', key: 'assignment_2_view' },
    { label: 'Final Viva view', key: 'final_viva_view' },
    { label: 'Final Project view', key: 'final_project_view' },
    { label: 'Total Marks', key: 'result' },
  ];






  return (
    <>
      <Box className="container">
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <h1>{courseInfo?.title}</h1>
         
          {
            tabsCount === "attendance" ? (
              <CSVDownloadButton
                data={reportData ?? []}
                variant={"black"}
                fileName={csvName}
              />
            ) : tabsCount === "grade_book" ? (
              <CSVDownloadButton
                headers={headers}
                data={gradeBookData ?? []} 
                variant={"black"}
                fileName={csvName}
              />
            ) : (
              <CSVDownloadButton
                apiEndpoint={apiUrl}
                variant={"black"}
                fileName={csvName}
                reRender={reRender}
              />
            )
          }

         
        </Grid>
        <Box className={styles.courseDetailFlex}>
          <Box className={styles.courseDetailFlexContent}>
            <Box className={styles.courseDetailFlexHeading}>
              <h2>{sectionInfo?.section_name}</h2>
              {/* <h2>Course</h2> */}
              <Typography variant="p" gutterBottom className={styles.coude}>
                {/* <span className="bold">{sectionInfo?.average_rating}</span>
                <span className="bold">{sectionInfo?.review_count} Reviews</span> */}
              </Typography>
            </Box>
            <Typography variant="p" gutterBottom className={styles.coude}>
              <span className="bold">{sectionInfo?.description}</span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Typography
                variant="p"
                gutterBottom
                className={styles.coude}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ImageView
                    imageName={sectionInfo?.profile_image}
                    name={sectionInfo?.name}
                  />
                  <span className={styles.bold}>
                    {sectionInfo?.teacher_name}
                  </span>{" "}
                  |
                </Box>
                <span className={styles.bold}>{sectionInfo?.cohort_name}</span>{" "}
                |<span className={styles.bold}>{sectionInfo?.shift_name}</span>
              </Typography>
            </Box>
          </Box>
          <Box className={styles.courseDetailSecond}>
            {/* <Typography variant="p" gutterBottom className={styles.coufee}>
              <span className="bold">{sectionInfo?.fee} PKR</span>
            </Typography> */}

            <Box className={styles.stuViewDetailBtn}>
              <HasPermission permission="manage-sections.edit">
                <Box
                  sx={{ width: "100%", marginBottom: "20px" }}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <SwitchCell
                    id={params.id}
                    is_active={sectionInfo?.status === "active" ? true : false}
                    EndPoint={AdminApi.SECTION_ACTIVE_INACTIVE}
                  />
                </Box>

                <Link className={styles.couViewBtn} to={""} onClick={View}>
                  Edit Section
                </Link>
              </HasPermission>
            </Box>
          </Box>
        </Box>
        <Grid container mt={"40px"}>
          <Card
            apiEndpoint={AdminApi.ENROLLED_LEARNER_TOTAL_COUNT}
            params={params.id}
            reloadEnroll={reloadEnroll}
          />
        </Grid>
        <CourseTabs
          courseId={courseID ?? ""}
          locationId={sectionInfo ? sectionInfo.location_id : ""}
          setTabsCount={setTabsCount}
          courseTitle={courseInfo ? courseInfo.title : ""}
          sectionName={sectionInfo ? sectionInfo.section_name : ""}
          sectionId={sectionInfo ? sectionInfo.id : ""}
          setReloadEnroll={setReloadEnroll}
          cohort_id={sectionInfo?.cohort_id}
          setReRender={setReRender}
        />
      </Box>
    </>
  );
}
